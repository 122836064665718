<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-12">
                        <form
                            class="form-horizontal form-horizontal-text-right"
                        >
                            <ts-panel class="mb-3">
                                <ts-panel-wrapper>
                                    <disbursement-profile
                                        v-model="model"
                                        :validation="errors"
                                        ref="disbursementProfile"
                                    />
                                </ts-panel-wrapper>
                            </ts-panel>
                            <div class="row">
                                <div class="col-md-3">
                                    <customer-profile v-model="model" />
                                </div>
                                <div class="col-md-9">
                                    <repayment-schedule
                                        ref="repaymentSchedule"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'loan-disbursement' })"
                class="btn-gray"
            >
                {{ $t('cancel') }}</ts-button
            >
            <ts-button
                color="warning"
                outline
                @click.prevent="clearInput"
            >
                {{ $t('reset') }}</ts-button
            >
            <ts-button
                color="info"
                outline
                :waiting="save_previewing"
                :disabled="waiting || isUpdate"
                @click.prevent="onSavePreview"
            >
                {{ $t('loanDisbursement.savePreviewSchedule') }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                :waiting="waiting_new"
                :disabled="waiting || isUpdate"
                @click.prevent="onSaveAddNew"
            >
                {{ $t('saveAddNew') }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new || isUpdate"
                @click.prevent="onSave"
            >
                {{ $t('save') }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";
import { isEmpty } from "lodash"
import disbursementProfile from "./components/disbursement-profile.vue";
import customerProfile from "./components/customer-profile.vue";
import repaymentSchedule from "./components/repayment-schedule.vue";

export default {
    name: "loanDisbursementCreate",
    components: {
        disbursementProfile,
        customerProfile,
        repaymentSchedule
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            save_previewing: false,
            errors: new Errors(),
            model: {
                loan_id: null,
                disbursement_date: moment().format("DD-MM-YYYY"),
                customer_id: null,
                voucher_number: null,
                loan_type_id: null,
                currency_id: 1,
                loan_amount: null,
                interest_rate: null,
                repayment_method_id: null,
                payment_interval: 1,
                first_installment_date: moment().add(1, 'days').format("DD-MM-YYYY"),
                installment_type_id: null,
                number_of_cycle: null,
                is_skip_sunday: false,
                amount_per_cycle: null,
                allow_pay_off_on_cycle: null,
                co_id: null,
                semi_baloon_effective_cycle: null,
                remarks: null,
                input_source: 'W',
                asset_down_payment_id: null,
            }
        };
    },
    computed: {
        ...mapState('creditOperation/loanDisbursement', ['edit_data']),
        isUpdate () {
            if (!isEmpty(this.edit_data)) {
                return true
            }
            return false
        },
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("creditOperation/loanDisbursement", [
            "getLoanType",
            "getCurrency",
            "getRepaymentMethod",
            "find",
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getLoanType();
            await this.getCurrency();
            await this.getRepaymentMethod();
            await this.$refs.disbursementProfile.loadEmployee();
            await this.$refs.disbursementProfile.loadCustomer();
            this.setCurrencyToDefault();
            this.loading = false;
        },
        setCurrencyToDefault () {
            this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id
        },
        getRepaymentSchedule (loan_id) {
            if(loan_id){
                this.$refs.repaymentSchedule.scheduleLoading = true;
                this.find(loan_id)
                    .then(() => {})
                    .catch(error => {
                        this.errors = new Errors(error.errors)
                        this.notice({ type: 'error', text: error.message })
                    })
                    .finally(() => {
                        this.$refs.repaymentSchedule.scheduleLoading = false;
                    })
                }
        },
        onSavePreview() {
            this.errors = new Errors();
            this.save_previewing = true;
            this.$store
                .dispatch("creditOperation/loanDisbursement/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.getRepaymentSchedule(response.resource_id);
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.save_previewing = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("creditOperation/loanDisbursement/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanDisbursement/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                    this.$router.push({ name: "loan-disbursement" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
                this.errors = new Errors();
                this.model.disbursement_date = moment().format("DD-MM-YYYY"),
                this.model.loan_id = null,
                this.model.customer_id = null,
                this.model.voucher_number = null,
                this.model.loan_type_id = null,
                this.model.currency_id = 1,
                this.model.loan_amount = null,
                this.model.interest_rate = null,
                this.model.repayment_method_id = null,
                this.model.payment_interval = 1,
                this.model.first_installment_date = moment().add(1, 'days').format("DD-MM-YYYY"),
                this.model.installment_type_id = null,
                this.model.number_of_cycle = null,
                this.model.is_skip_sunday = false,
                this.model.amount_per_cycle = null,
                this.model.allow_pay_off_on_cycle = null,
                this.model.co_id = null,
                this.model.semi_baloon_effective_cycle = 0,
                this.model.remarks = null,
                this.$store.commit("creditOperation/loanDisbursement/RESET_STATE");
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN DISBURSEMENT",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        this.$store.commit("creditOperation/loanDisbursement/RESET_STATE");
        this.$store.commit("creditOperation/loanDisbursement/CLEAR_RESOURCES");
        next();
    }
};
</script>
